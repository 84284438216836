import React, { Component } from 'react';
import { render } from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import MediaQuery from 'react-responsive';
//////////////////////////EN VERSION/////////////////////////////
import WideScreenHero from './Slides/WideScreen/HeroSlide/Hero';
import WideScreenWork from './Slides/WideScreen/WorkSlide/Work';
import WideScreenSkills from './Slides/WideScreen/Skills';
import WideScreenContact from './Slides/WideScreen/ContactSlide/Contact';
import MobileHero from './Slides/Mobile/HeroSlide/Hero';
import MobileWork from './Slides/Mobile/WorkSlide/Work';
import MobileSkills from './Slides/Mobile/Skills';
import MobileContact from './Slides/Mobile/ContactSlide/Contact';
//////////////////////////BG VERSION/////////////////////////////
import WideScreenHeroBG from './Slides/WideScreenBG/HeroSlide/Hero';
import WideScreenWorkBG from './Slides/WideScreenBG/WorkSlide/Work';
import WideScreenSkillsBG from './Slides/WideScreenBG/Skills';
import WideScreenContactBG from './Slides/WideScreenBG/ContactSlide/Contact';
import MobileHeroBG from './Slides/MobileBG/HeroSlide/Hero';
import MobileWorkBG from './Slides/MobileBG/WorkSlide/Work';
import MobileSkillsBG from './Slides/MobileBG/Skills';
import MobileContactBG from './Slides/MobileBG/ContactSlide/Contact';
import './Assets/index.css';

const GlobalStyle = createGlobalStyle`
html, body { margin: 0;}
*, *:before, *:after { box-sizing: border-box; }
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: 'EN',
    };
  }
  componentDidMount() {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
    fetch("https://ip.nf/me.json")
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          lang: result.ip.country_code
        });
      },
      (error) => {
        this.setState({
          lang: 'EN'
        });
      }
    )
  }

  render() {
    const { lang } = this.state;
    return (
      <React.Fragment>
        <MediaQuery query="(min-device-width: 1224px)">
          {lang == 'BG' ? <WideScreenHeroBG /> :<WideScreenHero />}
          {lang == 'BG' ? <WideScreenWorkBG /> :<WideScreenWork />}
          {lang == 'BG' ? <WideScreenSkillsBG /> :<WideScreenSkills />}
          {lang == 'BG' ? <WideScreenContactBG /> :<WideScreenContact />}
        </MediaQuery>
        <MediaQuery query="(max-device-width: 1224px)">
        {lang == 'BG' ? <MobileHeroBG /> :<MobileHero />}
          {lang == 'BG' ? <MobileWorkBG /> :<MobileWork />}
          {lang == 'BG' ? <MobileSkillsBG /> :<MobileSkills />}
          {lang == 'BG' ? <MobileContactBG /> :<MobileContact />}
        </MediaQuery>
        <GlobalStyle />
      </React.Fragment>
    );
  }
}

render(React.createElement(App), document.getElementById('root'));
